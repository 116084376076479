import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import headerImg from '../assets/img/header-img.svg';
// import { ArrowRightCircle, Quote } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { CodingAnimation } from './CodingAnimation/CodingAnimation';
import { SocialButtons } from './SocialButtons/SocialButtons';

export const Banner = () => {
  // const [loopNum, setLoopNum] = useState(0);
  // const [isDeleting, setIsDeleting] = useState(false);
  // const [text, setText] = useState('');
  // const [delta, setDelta] = useState(300 - Math.random() * 100);
  // const [index, setIndex] = useState(1);
  // const period = 2000;

  // useEffect(() => {
  //   let ticker = setInterval(() => {
  //     tick();
  //   }, delta);

  //   return () => {
  //     clearInterval(ticker);
  //   };
  // }, [text]);

  // const tick = () => {
  // let i = loopNum % toRotate.length;
  // let fullText = toRotate[i];
  //   let updatedText = isDeleting
  //     ? fullText.substring(0, text.length - 1)
  //     : fullText.substring(0, text.length + 1);

  //   setText(updatedText);

  //   if (isDeleting) {
  //     setDelta((prevDelta) => prevDelta / 2);
  //   }

  //   if (!isDeleting && updatedText === fullText) {
  //     setIsDeleting(true);
  //     setIndex((prevIndex) => prevIndex - 1);
  //     setDelta(period);
  //   } else if (isDeleting && updatedText === '') {
  //     setIsDeleting(false);
  //     setLoopNum(loopNum + 1);
  //     setIndex(1);
  //     setDelta(500);
  //   } else {
  //     setIndex((prevIndex) => prevIndex + 1);
  //   }
  // };

  // return (
  //   <section className='banner' id='home'>
  //     <Container>
  //       <Row className='aligh-items-center'>
  //         <Col xs={12} md={6} xl={7}>
  //           <TrackVisibility>
  //             {({ isVisible }) => (
  //               <div
  //                 className={
  //                   isVisible ? 'animate__animated animate__fadeIn' : ''
  //                 }
  //               >
  //                 <h1>{"Hi I'm Calvin,"} </h1>
  //                 <h4>
  //                   I am a full-stack developer with a background in accounting.
  //                 </h4>
  //                 <SocialButtons />
  //                 <div className='social-buttons'></div>
  //               </div>
  //             )}
  //           </TrackVisibility>
  //         </Col>
  //         <Col xs={12} md={6} xl={5}>
  //           <TrackVisibility>
  //             {({ isVisible }) => (
  //               <div
  //                 className={
  //                   isVisible ? 'animate__animated animate__zoomIn' : ''
  //                 }
  //               >
  //                 <div className='animation'>
  //                   <CodingAnimation />
  //                 </div>
  //               </div>
  //             )}
  //           </TrackVisibility>
  //         </Col>
  //       </Row>
  //     </Container>
  //   </section>
  // );

  return (
    <section className='banner' id='home'>
      <Container>
        <Row className='aligh-items-center'>
          <Col xs={12} md={6} xl={7}>
            <div>
              <h1>{"Hi I'm Calvin,"} </h1>
              <h4>
                I am a full-stack developer with a background in accounting.
              </h4>
              <br></br>
              <SocialButtons />
              <div className='social-buttons'></div>
            </div>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <div>
              <div className='animation'>
                <CodingAnimation />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
